import React from 'react'
import * as moment from 'moment'

export default function PrivacyPolicy() {
  return (
    <>
      <div className='head'>
        <span className='as-of'>Current as of {moment().format('DD MMMM YYYY')}</span>
        <h1 className='terms-title'>Privacy Policy</h1>
        <div className='privacy-sub-title'>At ExpressJobs, we respect your privacy and are committed to protecting your personal information. This Privacy Policy outlines how we collect, use, and safeguard your data.</div>
      </div>
      <div className='privacy-content-area'>
        <div className='privacy-content'>
          <h2>Information We Collect</h2>
          <ul>
            <li>Personal Information: We collect information such as your name, email address, phone number, and payment details when you register an account, book a service, or contact us.</li>
            <li>Usage Data: We collect data about how you use our Services, including your IP address, device information, and browsing activity.</li>
          </ul>
        </div>
        <div className='privacy-content'>
          <h2>How We Use Your Information</h2>
          <ul>
            <li>We use your information to:</li>
            <li>Provide and improve our Services</li>
            <li>Process payments and manage bookings</li>
            <li>Communicate with you about your account or services</li>
            <li>Personalize your experience on our platform</li>
            <li>Prevent fraud and enhance security</li>
          </ul>
        </div>
        <div className='privacy-content'>
          <h2>Sharing Your Information</h2>
          <p>We do not sell or rent your personal information to third parties. However, we may share your data with:</p>
          <ul><li>Service Providers: To facilitate payments, service bookings, and other functions necessary to operate the app.</li>
            <li>Legal Obligations: If required by law, we may disclose your information to comply with legal processes or protect our rights.</li></ul>
        </div>
        <div className='privacy-content'>
          <h2>Data Security</h2>
          <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no method of transmission over the internet is completely secure, and we cannot guarantee the absolute security of your data.</p>
        </div>
        <div className='privacy-content'>
          <h2>Your Rights</h2>
          <p>You have the right to:</p>
          <ul>
            <li>Access, update, or delete your personal information</li>
            <li>Opt-out of marketing communications</li>
            <li>Request a copy of the data we hold about you</li></ul>
          <p>To exercise these rights, please contact us at [Insert Contact Information].</p>
        </div>
        <div className='privacy-content'>
          <h2>Cookies and Tracking Technologies</h2>
          <p>We use cookies and similar tracking technologies to enhance your experience on our platform. You can control the use of cookies through your browser settings.</p>
        </div>
        <div className='privacy-content'>
          <h2>Children's Privacy</h2>
          <p>Our Services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children under 18.</p>
        </div>
        <div className='privacy-content'>
          <h2>Changes to Privacy Policy</h2>
          <p>We may update this Privacy Policy from time to time. If we make significant changes, we will notify you by email or through the app.</p>
        </div>
        <div className='privacy-content'>
          <h2>Contact Information</h2>
          <p>If you have any questions about this Privacy Policy, please contact us at +27 78 619 1574.</p>
        </div>
      </div>
    </>

  )
}
