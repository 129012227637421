import React from "react";

export default function Alertmodal({ onClose }) {
  return (
    <div className="modalAlert">
      <div className="modalInnertext">
        <img src="/assets/Logo.svg" alt="" />
        <p>We Are Launching Soon! </p>
        <button onClick={onClose} className="soonBtn">
          OK
        </button>
      </div>
    </div>
  );
}
