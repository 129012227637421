import React, { useState } from "react";
import { RoutesPaths } from "../Router/constant";
import { Link } from "react-router-dom";
import Alertmodal from "./Alertmodal";

export default function Footer() {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handlePlaystoreClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div className="footerbackground">
        <div className="container-fluid screenResolation footerSection">
          <div className="row">
            <div className="col-6 col-md-6">
              <img src="/assets/Logo.svg" alt="" />
            </div>
            <div className="col-6 col-md-6 socialIconSection">
              <div className="socialIcon cursorPointer">
                <img src="/assets/linkedin.svg" alt="" />
              </div>
              <div className="socialIcon cursorPointer">
                <img src="/assets/twitter.svg" alt="" />
              </div>
            </div>
          </div>
          <div className="row centerfooterSection">
            <div className="col-md-6 leftAppLink">
              <p className="m-0">Download the app</p>
              <div className="alignCenter">
                <img
                  src="/assets/applestore.svg"
                  alt=""
                  onClick={handlePlaystoreClick}
                />
                <img
                  src="/assets/playstore.svg"
                  alt=""
                  onClick={handlePlaystoreClick}
                />
              </div>
            </div>
            <div className="col-md-6 InfoSection">
              <p className="m-0 userDetailsLinkHeader">Contact Us</p>
              <div className="">
                <a
                  href="https://wa.me/786191574"
                  target="_blank"
                  rel="noreferrer"
                  className="userDetailsLink"
                >
                  <img src="/assets/whatsapp.svg" alt="" />
                  <p className="m-0">WhatsApp: +27 78 619 1574</p>
                </a>
              </div>
              <div className="">
                <a
                  href="tel:786191574"
                  target="_blank"
                  rel="noreferrer"
                  className="userDetailsLink"
                >
                  <img src="/assets/call.svg" alt="" />
                  <p className="m-0">Product enquiries: +27 78 619 1574</p>
                </a>
              </div>
              <div className="">
                <a
                  href="mailto:admin:@expressjobs.co.za"
                  rel="noreferrer"
                  target="_blank"
                  className="userDetailsLink"
                >
                  <img src="/assets/mail.svg" alt="" />
                  <p className="m-0">admin@expressjobs.co.za</p>
                </a>
              </div>
            </div>
          </div>
          <div className="row bottomSection">
            <div className="col-md-6">
              <p className="m-0 policyText">Copyright © 2024 ExpressJobs</p>
            </div>
            <div className="col-md-6 policyLinkSection">
              <Link
                to={RoutesPaths.TERMSCONDITIONS}
                className="textDecorationNone"
              >
                <div className="policyText cursorPointer">Terms of service</div>
              </Link>
              <Link
                to={RoutesPaths.TERMSCONDITIONS}
                className="textDecorationNone"
              >
                <div className="policyText cursorPointer">
                  Terms of Advisory Plan
                </div>
              </Link>
              <Link
                to={RoutesPaths.PRIVACYPOLICY}
                className="textDecorationNone"
              >
                <div className="policyText cursorPointer">Privacy Policy</div>
              </Link>
            </div>
          </div>
        </div>
      </div>

      {isModalVisible && <Alertmodal onClose={handleCloseModal} />}
    </>
  );
}
