import React from "react";
import { Route, Routes } from "react-router-dom";
import { RoutesPaths } from "./constant";
import Landing from "../Pages/Landing";
import TermsConditions from "../Components/TermsConditions";
import PrivacyPolicy from "../Components/PrivacyPolicy";
import AccountClose from "../Pages/AccountClose";

export default function RouteComponent() {
  return (
    <>
      <Routes>
        <Route exact path={RoutesPaths.LANDING} element={<Landing />} />
        <Route exact path={RoutesPaths.TERMSCONDITIONS} element={<TermsConditions />} />
        <Route exact path={RoutesPaths.PRIVACYPOLICY} element={<PrivacyPolicy />} />
        <Route exact path={RoutesPaths.ACCOUNTCLOSE} element={<AccountClose />} />
      </Routes>
    </>
  );
}
