import React, { useState } from "react";
import { useForm } from "react-hook-form";

export default function AccountClose() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    setIsModalOpen(true);
    reset();
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <div className="outerDeletePage">
        <div className="container-fluid screenResolation">
          <div className="row acclountcloseForm">
            <div className="col-md-6">
              <img
                src="/assets/accountClose.png"
                alt=""
                className="img-fluid"
              />
            </div>
            <div className="col-md-6 acFormBackground">
              <div>
                <h3 className="closeformTitle">Account Closing Form</h3>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input
                    maxLength={50}
                    autoComplete="nope"
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter your name"
                    {...register("first_name", {
                      required: true,
                      maxLength: 20,
                      minLength: 3,
                    })}
                  />

                  <input
                    maxLength={50}
                    autoComplete="nope"
                    type="email"
                    className="form-control mb-3"
                    placeholder="Enter your email"
                    {...register("email", {
                      required: true,
                    })}
                  />

                  <textarea
                    maxLength={50}
                    autoComplete="nope"
                    type="text"
                    className="form-control mb-3"
                    placeholder="Enter note here ...."
                    {...register("note", {
                      required: true,
                      minLength: 3,
                    })}
                  />
                  <button type="submit" className="btn downloadApp">
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <div className="modal">
          <div className="modal-contentConfirm">
            <h3>Confirm</h3>
            <p>
            Your account closure request has been sent successfully. Please wait for a response. Once your account is closed, you will no longer be able to create jobs or perform job searches through this account.
            </p>
            <div>
              <button className="soonBtn" onClick={closeModal}>
                OK
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
