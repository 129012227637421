import React, { useState } from "react";
import { RoutesPaths } from "../Router/constant";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Header() {
  const [activeSection, setActiveSection] = useState(""); // Track the active section
  const [menuVisible, setMenuVisible] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const toggleMenu = () => {
    setMenuVisible(!menuVisible);
  };

  const scrollToSection = (sectionId) => {
    if (location.pathname !== RoutesPaths.LANDING) {
      navigate(RoutesPaths.LANDING);
    }
    setTimeout(() => {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
        // setTimeout(() => {
        //   section.classList.remove('scroll-margin');
        // }, 500); // Adjust timing as needed
  
        setActiveSection(sectionId);
      }
    }, 100);
  };

  return (
    <div className="headerShadowSection">
      <div className="container-fluid screenResolation">
        <div className="row headerSection">
          <div className="col-md-2 alignCenter">
            <img
              src="/assets/threeLineIcon.svg"
              alt=""
              className="threeLine visibleMobile"
              onClick={toggleMenu}
              style={{ cursor: "pointer" }}
            />
            <Link to={RoutesPaths.LANDING} className="textDecorationNone">
              <img src="/assets/Logo.svg" alt="" />
            </Link>
          </div>

          <div
            className={`col-md-8 alignCenter menuSection ${
              menuVisible ? "showMenu" : "hideMenu"
            }`}
          >
            <div
              onClick={() => scrollToSection("platform")}
              style={{
                cursor: "pointer",
                color: activeSection === "platform" ? "#0072bb" : "black", // Change color for active section
              }}
            >
              Platform
            </div>
            <div
              onClick={() => scrollToSection("services")}
              style={{
                cursor: "pointer",
                color: activeSection === "services" ? "#0072bb" : "black", // Change color for active section
              }}
            >
              Services
            </div>
            <div
              onClick={() => scrollToSection("itWorks")}
              style={{
                cursor: "pointer",
                color: activeSection === "itWorks" ? "#0072bb" : "black", // Change color for active section
              }}
            >
              How It Works
            </div>
            <div
              onClick={() => scrollToSection("getStarted")}
              style={{
                cursor: "pointer",
                color: activeSection === "getStarted" ? "#0072bb" : "black", // Change color for active section
              }}
            >
              Get Started
            </div>
            <div
              onClick={() => scrollToSection("faqs")}
              style={{
                cursor: "pointer",
                color: activeSection === "faqs" ? "#0072bb" : "black", // Change color for active section
              }}
            >
              FAQs
            </div>
          </div>

          <div
            className={`col-md-2 alignCenter downloadSection ${
              menuVisible ? "showMenu" : "hideMenu"
            }`}
          >
            <button
              className="downloadApp"
              onClick={() => scrollToSection("getStarted")}
            >
              Download App
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
