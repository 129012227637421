import React, { useState } from "react";
import Alertmodal from "../Components/Alertmodal";

export default function Landing() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? -1 : index);
  };

  const sections = [
    "What types of services does ExpressJobs offer?",
    "How do I book a service through ExpressJobs?",
    "Are the professionals on ExpressJobs vetted and trustworthy?",
    "Can I reschedule or cancel a service after booking?",
    "What if I'm not satisfied with the service provided?",
    "How do I pay for services on ExpressJobs?",
  ];

  const content = [
    'ExpressJobs offers a wide range of services from home maintenance to professional specialized services, such as tutors, graphic designers, DStv installation, architects and much more. Our platform connects you with service providers for all your needs!',
    'Sign-up on the app and browse through thousands of services. Select your desired service provider based on ratings, affordability and personal preferences. Connect with your selected service provider in our in-app chat. It’s that simple!',
    'Absolutely, we’ve done the legwork to ensure that those who sign-up do not pose a threat to the community and that those who provide professional specialized service have the necessary degree and qualifications to do so!',
    'The in-app chat allows direct communication with the service provider, if you need to reschedule, you’re more than welcome to do so. However, once a booked request has been sent out and accepted, you will unfortunately be charged a minor fee for cancellation.',
    'If you are not satisfied with the service provided, ensure that that you rate your service provider accordingly and get in touch with our team. We’ll be glad to help!',
    'Load funds on our in-app wallet though the secure payment gateway. Funds in the wallet will appear as credits and will be deducted upon successful completion of the service. We recommend this, as all funds will be deducted and released by us, ensuring safe and fair transaction between the user and the service provider.'
  ]
  // const content = `ExpressJobs connects you with trusted professionals ready to tackle any task, big or small. From home maintenance to professional specialized services, we connect you with the best service provider at your doorstep.`;

  const handlePlaystoreClick = () => {
    setIsModalVisible(true);
  };

  const handleCloseModal = () => {
    setIsModalVisible(false);
  };

  // const handleClickOutside = useCallback((event) => {
  //   if (modalRef.current && !modalRef.current.contains(event.target)) {
  //     handleCloseModal();
  //   }
  // }, []);

  // useEffect(() => {
  //   if (isModalVisible) {
  //     document.addEventListener("mousedown", handleClickOutside);
  //   } else {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   }

  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, [isModalVisible, handleClickOutside]);

  return (
    <>
      <section id="platform">
        <div className="landingOuterSection">
          <div className="container-fluid screenResolation welcomeBackground">
            <div className="row landingPageInnerSection">
              <div className="col-md-6">
                <h1 className="m-0 herotext">
                  Welcome to ExpressJobs, Your One-Stop Solution for Every Need
                </h1>
                <p className="m-0 herotextPera">
                ExpressJobs connects you with trusted professionals ready to tackle any task, big or small. From home maintenance to professional specialized services, we connect you with the best service provider at your doorstep.
                </p>

                <div className="leftAppLink">
                  <p className="m-0">Download the app</p>
                  <div className="alignCenter">
                    <img
                      src="/assets/applestore.svg"
                      alt=""
                      onClick={handlePlaystoreClick}
                    />
                    <img
                      src="/assets/playstore.svg"
                      alt=""
                      onClick={handlePlaystoreClick}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 imgResponsive">
                <img src="/assets/heroSection.png" alt="" className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="services">
        <div className="container-fluid screenResolation popularServicesBG">
          <div className="row">
            <div className="col-md-12">
              <div className="serviesHeader">
                <p className="m-0">Choose a Role</p>
                <h3 className="m-0">Popular Services at Your Fingertips</h3>
              </div>

              <div className="container-fluid innerPopularSection">
                <div className="row">
                  <div className="col-md-5">
                    <img src="/assets/popularServices.png" alt="" />
                  </div>
                  <div className="col-md-7">
                    {/* <p className="m-0 servicePopularTitle">
                      Which staff can be hired quickly, with document and
                      qualifications?
                    </p> */}

                    <div className="popularServices">
                      <div className="popularServicesBox">
                        <div className="popularServicesAlign">
                          <img src="/assets/HomeMaintenance.svg" alt="" />
                          <p className="m-0">Home Maintenance</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/Painting.svg" alt="" />
                          <p className="m-0">Painting</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/HandymanServices.svg" alt="" />
                          <p className="m-0">Handyman Services</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/MountingInstallation.svg" alt="" />
                          <p className="m-0">Mounting & Installation</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/YardWork.svg" alt="" />
                          <p className="m-0">Yard Work</p>
                        </div>
                      </div>

                      <div className="popularServicesBox">
                        <div className="popularServicesAlign">
                          <img src="/assets/HairSalons.svg" alt="" />
                          <p className="m-0">Hair Salons</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/MovingTransport.svg" alt="" />
                          <p className="m-0">Moving & Transport</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/FurnitureAssembly.svg" alt="" />
                          <p className="m-0">Furniture Assembly</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/Cleaning.svg" alt="" />
                          <p className="m-0">Cleaning</p>
                        </div>

                        <div className="popularServicesAlign">
                          <img src="/assets/PersonalAssistance.svg" alt="" />
                          <p className="m-0">Personal Assistance</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="itWorks">
        <div className="container-fluid screenResolation">
          <div className="row">
            <div className="col-md-12">
              <div className="serviesHeader">
                <p className="m-0">Choose a Role</p>
                <h3 className="m-0">Popular Services at Your Fingertips</h3>
              </div>
            </div>
          </div>

          <div className="row workjobSection">
            <div className="col-md-3">
              <div className="servicesDetails">
                <img src="/assets/BrowseServices.svg" alt="" />
                <h6 className="m-0">Browse Services</h6>
                <p className="m-0">
                  Explore a wide range of services tailored to meet your needs,
                  whether it's a handyman for quick fixes or personal assistance
                  for your daily tasks.
                </p>
              </div>

              <div className="servicesDetails">
                <img src="/assets/BookandRelax.svg" alt="" />
                <h6 className="m-0">Book and Relax</h6>
                <p className="m-0">
                Schedule the required service at your convenience, and the service providers will handle the rest. We make it hassle-free.
                </p>
              </div>
            </div>
            <div className="col-md-6"></div>

            <div className="col-md-3">
              <div className="servicesDetails">
                <img src="/assets/ChooseProfessional.svg" alt="" />
                <h6 className="m-0">⁠Choose a Service Provider                </h6>
                <p className="m-0">
                Select from a list of vetted service providers in your area, each with detailed profiles, ratings, and reviews to ensure you make the right choice. 
                </p>
              </div>

              <div className="servicesDetails">
                <img src="/assets/EnjoyQualityService.svg" alt="" />
                <h6 className="m-0">Enjoy Quality Service</h6>
                <p className="m-0">
                Experience top-notch service, ExpressJobs guarantees satisfaction with every job.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="getStarted">
        <div className="jobTodaySection">
          <div className="container-fluid screenResolation">
            <div className="row landingPageInnerSection jobTodaySection">
              <div className="col-md-6 leftjobToday">
                <h1 className="m-0 herotext">
                  Get Started with ExpressJobs Today!
                </h1>
                <p className="m-0 herotextPera">
                Ready to simplify your life? Download the app and find the right service provider for any task. Your satisfaction is our priority!
                </p>

                <div className="leftAppLink">
                  <p className="m-0">Download the app</p>
                  <div className="alignCenter">
                    <img
                      src="/assets/applestore.svg"
                      alt=""
                      onClick={handlePlaystoreClick}
                    />
                    <img
                      src="/assets/playstore.svg"
                      alt=""
                      onClick={handlePlaystoreClick}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-6 imgResponsive">
                <img src="/assets/getContent.png" alt="" className="img-fluid"/>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="faqs">
        <div className="container-fluid screenResolation">
          <div className="row faqSection">
            <div className="col-md-8">
              <h4 className="faqTitle">Frequently asked questions</h4>

              <div>
                {sections.map((title, index) => (
                  <div
                    className={`service-toggle ${
                      activeIndex === index ? "service-toggleActive" : ""
                    }`}
                    key={index}
                  >
                    <div className="LeftmessageSectio">
                      <img
                        src="/assets/plus.svg"
                        alt=""
                        onClick={() => toggleSection(index)}
                        className={`toggle-icon ${
                          activeIndex === index ? "hidden" : ""
                        }`}
                      />
                      <img
                        src="/assets/minus.svg"
                        alt=""
                        onClick={() => toggleSection(index)}
                        className={`toggle-icon ${
                          activeIndex === index ? "" : "hidden"
                        }`}
                      />
                    </div>

                    <div className="rightmessageSectio">
                      <h6
                        className="m-0 cursorPointer"
                        onClick={() => toggleSection(index)}
                      >
                        {title}
                      </h6>
                      <p
                        className={`m-0 toggle-content ${
                          activeIndex === index ? "open" : ""
                        }`}
                      >
                        {content[index]}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>

      {isModalVisible && (
        <Alertmodal onClose={handleCloseModal} />
      )}
    </>
  );
}
