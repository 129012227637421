import React from 'react'
import * as moment from 'moment'
export default function TermsConditions() {
  return (
    <>    
      <div className='head'>
          <span className='as-of'>Current as of { moment().format('DD MMMM YYYY') }</span>
          <h1 className='terms-title'>Terms of Service</h1>
          <div className='privacy-sub-title'>Welcome to ExpressJobs! These Terms of Service ("Terms") govern your access to and use of the ExpressJobs app and services ("Services"). By using our Services, you agree to these Terms. Please read them carefully.</div>
      </div>
      <div className='privacy-content-area'>
          <div className='privacy-content'>
              <h2>Acceptance of Terms</h2>
              <p>By accessing or using the Services, you agree to be bound by these Terms and any additional terms that apply to specific services. If you do not agree, please do not use the Services.</p>
          </div>
          <div className='privacy-content'>
              <h2>Use of Services</h2>
              <p>You must be at least 18 years old to use our Services. You agree to use the Services only for lawful purposes and in accordance with these Terms. You are responsible for ensuring that your use of the Services complies with all applicable laws and regulations..</p>
          </div>
          <div className='privacy-content'>
              <h2>Account Registration</h2>
              <p>To access certain features of the Services, you may need to create an account. You agree to provide accurate, current, and complete information during the registration process and to keep your account information updated. You are responsible for maintaining the confidentiality of your account and password.</p>
          </div>
          <div className='privacy-content'>
              <h2>Service Booking and Payment</h2>
              <p>When you book a service through ExpressJobs, you agree to pay the specified price for the service. All payments are processed securely through our app. We reserve the right to cancel any booking if we suspect fraudulent activity.</p>
          </div>
          <div className='privacy-content'>
              <h2>Cancellations and Refunds</h2>
              <p>Cancellations and refunds are subject to the policies outlined in our FAQ. Please review these policies carefully before booking a service.</p>
          </div>
          <div className='privacy-content'>
              <h2>User Conduct</h2>
              <p>You agree not to misuse the Services, including but not limited to interfering with the operation of the Services, accessing data not intended for you, or violating the rights of others.</p>
          </div>
          <div className='privacy-content'>
              <h2>Intellectual Property</h2>
              <p>All content, trademarks, and other intellectual property on the ExpressJobs platform are owned by us or our licensors. You may not use, reproduce, or distribute any content from the Services without our prior written consent.</p>
          </div>
          <div className='privacy-content'>
              <h2>Limitation of Liability</h2>
              <p>ExpressJobs is not liable for any indirect, incidental, special, or consequential damages arising from your use of the Services. Our liability is limited to the amount you paid for the Services.</p>
          </div>
          <div className='privacy-content'>
              <h2>Termination</h2>
              <p>We reserve the right to terminate or suspend your account and access to the Services at our discretion, without notice, for conduct that we believe violates these Terms or is harmful to other users or us.</p>
          </div>
          <div className='privacy-content'>
              <h2>Changes to Terms</h2>
              <p>We may update these Terms from time to time. If we make significant changes, we will notify you by email or through the app. Your continued use of the Services after changes have been made constitutes your acceptance of the new Terms.</p>
          </div>
          <div className='privacy-content'>
              <h2>Contact Information</h2>
              <p>If you have any questions about these Terms, please contact us at +27 78 619 1574.</p>
          </div>
      </div>
      </>
  )
}
