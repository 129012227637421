import { BrowserRouter } from "react-router-dom";
import "./App.css";
import Footer from "./Components/Footer";
import Header from "./Components/Header";
import RouteComponent from "./Router/RouteComponent";
import ScrollToTop from "./scrolltotop";

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop />
        <Header />
        <RouteComponent></RouteComponent>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
